.nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nav-links li {
  margin: 1rem;
  font-size: 0.8rem;
}

.nav-links a {
  border: 1px solid transparent;
  color: #292929;
  text-decoration: none;
  padding: 0.5rem;
}

.nav-links a:hover,
.nav-links a:active,
.nav-links a.active {
  background: #af5d15;
  border-color: #ffffff;
  color: #ffffff;
  transition: all 0.3s ease-out;
}

.nav-links button {
  cursor: pointer;
  border: 0px solid #292929 !important;
  color: #292929;
  background: transparent;
  padding: 0.5rem;
  font: 10px;
  transition: all 0.1s ease-out;
}

.nav-links button:focus {
  outline: none;
}

.nav-links button:hover,
.nav-links button:active {
  background: #af5d15;
  color: white;
}

.nav-items-dropdown {
  display: flex;
  align-items: center;
  height: auto;
}

@media (min-width: 768px) {
  .nav-items-dropdown {
    display: flex;
    align-items: center;
    height: auto;
  }

  .nav-links {
    flex-direction: row;
  }

  .nav-links li {
    margin: 0 0.5rem;
  }

  .nav-links a {
    color: white !important;
    text-decoration: none;
  }

  .nav-links button {
    border: 1px solid white;
    color: white;
    background: transparent;
  }

  .nav-links button:hover,
  .nav-links button:active {
    background: #fad221;
    color: #292929;
  }
}
