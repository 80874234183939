@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap);
* {
  box-sizing: border-box;
}

html {
  font-family: 'Open Sans', sans-serif;
}

body {
  margin: 0;
  background: #cdcdcd;
}

.slide-in-left-enter {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.slide-in-left-enter-active {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  opacity: 1;
  transition: all 200ms;
}

.slide-in-left-exit {
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
  opacity: 1;
}

.slide-in-left-exit-active {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  opacity: 0;
  transition: all 200ms;
}

.center {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.orange {
  color: #ff6f00;
}

.tippy-content-span {
  color: #ff6f00;
}

.tippy-span {
  color: white;
  background: orange;
}
.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
}

.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #510077;
  border-color: #510077 transparent #510077 transparent;
  -webkit-animation: lds-dual-ring 1.2s linear infinite;
          animation: lds-dual-ring 1.2s linear infinite;
}

.loading-spinner__overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

@-webkit-keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.main-header {
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background: #ff6f00;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
  padding: 0 1rem;
  z-index: 5;
}

main {
  margin-top: 4rem;
}

@media (min-width: 768px) {
  .main-header {
    justify-content: space-between;
  }
}
.nav-dropdown-menu {
  width: auto;
  position: absolute;
  top: 50px;
  right: 80px;
  list-style: none;
  text-align: start;
}

.nav-dropdown-menu li {
  background: #ff9502;
  cursor: pointer;
}

.nav-dropdown-menu li:hover {
  background: #cb7600;
}

.nav-dropdown-profile-menu {
  width: auto;
  position: absolute;
  top: 50px;
  right: 10px;
  list-style: none;
  text-align: start;
}

.nav-dropdown-profile-menu li {
  background: #ff9502;
  cursor: pointer;
}

.nav-dropdown-profile-menu li:hover {
  background: #cb7600;
}

.nav-drop-menu {
  display: none;
}

.drop-section{
    background: transparent;
}

.nav-menu-items {
  display: grid;
  text-decoration: none;
  color: #fff;
  padding: 15px;
}

@media screen and (max-width: 767px) {
  .nav-dropdown-menu {
    width: auto;
    position: relative;
    top: unset;
    list-style: none;
    text-align: start;
    right: 24px;
  }
  .nav-menu-items {
    display: grid;
    text-decoration: none;
    color: #fff;
    padding: 15px;
  }

  .nav-dropdown-menu a {
    color: #fff !important;
  }

  .nav-dropdown-profile-menu {
    width: auto;
    position: relative;
    top: unset;
    list-style: none;
    text-align: start;
    right: 24px;
  }

  .nav-dropdown-profile-menu a {
    color: #fff !important;
  }
}

.nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nav-links li {
  margin: 1rem;
  font-size: 0.8rem;
}

.nav-links a {
  border: 1px solid transparent;
  color: #292929;
  text-decoration: none;
  padding: 0.5rem;
}

.nav-links a:hover,
.nav-links a:active,
.nav-links a.active {
  background: #af5d15;
  border-color: #ffffff;
  color: #ffffff;
  transition: all 0.3s ease-out;
}

.nav-links button {
  cursor: pointer;
  border: 0px solid #292929 !important;
  color: #292929;
  background: transparent;
  padding: 0.5rem;
  font: 10px;
  transition: all 0.1s ease-out;
}

.nav-links button:focus {
  outline: none;
}

.nav-links button:hover,
.nav-links button:active {
  background: #af5d15;
  color: white;
}

.nav-items-dropdown {
  display: flex;
  align-items: center;
  height: auto;
}

@media (min-width: 768px) {
  .nav-items-dropdown {
    display: flex;
    align-items: center;
    height: auto;
  }

  .nav-links {
    flex-direction: row;
  }

  .nav-links li {
    margin: 0 0.5rem;
  }

  .nav-links a {
    color: white !important;
    text-decoration: none;
  }

  .nav-links button {
    border: 1px solid white;
    color: white;
    background: transparent;
  }

  .nav-links button:hover,
  .nav-links button:active {
    background: #fad221;
    color: #292929;
  }
}

.side-drawer {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    height: 100vh;
    width: 70%;
    background: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.40);
  z-index: 10;
}

.main-navigation__menu-btn {
  width: 3rem;
  height: 3rem;
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 2rem;
  cursor: pointer;
}

.main-navigation__menu-btn span {
  display: block;
  width: 3rem;
  height: 2.5px;
  background: white;
}

.main-navigation__title {
  color: white;
  margin-bottom: 0rem;
  padding-bottom: 0rem;
  line-height: 1.2rem;
}

.main-navigation__title a {
  text-decoration: none;
  color: white;
}
.main-navigation__title div {
  color: #ffb567;
  font-size: 11px;
}

.main-navigation__title span {
  color: #ffb567;
  font-size: 12px;
  font-weight:lighter;
}

.main-navigation__header-nav {
  display: none;
}

.main-navigation__drawer-nav {
  height: 100%;
}

@media (min-width: 768px) {
  .main-navigation__menu-btn {
    display: none;
  }

  .main-navigation__header-nav {
    display: block;
  }
}
