.nav-dropdown-menu {
  width: auto;
  position: absolute;
  top: 50px;
  right: 80px;
  list-style: none;
  text-align: start;
}

.nav-dropdown-menu li {
  background: #ff9502;
  cursor: pointer;
}

.nav-dropdown-menu li:hover {
  background: #cb7600;
}

.nav-dropdown-profile-menu {
  width: auto;
  position: absolute;
  top: 50px;
  right: 10px;
  list-style: none;
  text-align: start;
}

.nav-dropdown-profile-menu li {
  background: #ff9502;
  cursor: pointer;
}

.nav-dropdown-profile-menu li:hover {
  background: #cb7600;
}

.nav-drop-menu {
  display: none;
}

.drop-section{
    background: transparent;
}

.nav-menu-items {
  display: grid;
  text-decoration: none;
  color: #fff;
  padding: 15px;
}

@media screen and (max-width: 767px) {
  .nav-dropdown-menu {
    width: auto;
    position: relative;
    top: unset;
    list-style: none;
    text-align: start;
    right: 24px;
  }
  .nav-menu-items {
    display: grid;
    text-decoration: none;
    color: #fff;
    padding: 15px;
  }

  .nav-dropdown-menu a {
    color: #fff !important;
  }

  .nav-dropdown-profile-menu {
    width: auto;
    position: relative;
    top: unset;
    list-style: none;
    text-align: start;
    right: 24px;
  }

  .nav-dropdown-profile-menu a {
    color: #fff !important;
  }
}
